.contact__section{
    background-color: #070b28;
    height: 25rem;
}

.contact__content{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2rem;
}

.left__side{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    border-right: 2px solid #3e3f40;
    padding-right: 15rem;
}

.message__input{
    border-radius: 10px;
    width: 20rem;
    border: none;
    resize: none;
    padding: 1rem;
}

.email{
    padding: 1rem;
    border: none;
    border-radius: 10px;
    width: 15rem;
}

.action{
    display: flex;
    gap: 1rem;
}

.button__send{
    color: #000;
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button__send i{
    font-size: 1.5rem;
    font-weight: bold;
}

.button__send:hover{
    background-color: #3e3f40;
    color: #fff;
}

/* Right Side */

.round{
    background-color: #3e3f40;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
}

.line{
    display: flex;
    
}

.line span{
    color: #fff;
    font-size: 1.5rem;
    margin-right: 2rem;
    font-weight: normal;
}

.details{
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 1rem;
}

.logo{
    margin-top: 2rem;
    width: 16rem;
}

@media only screen and (max-width: 950px){
    .contact__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .left__side{
        border: none;
        position: relative;
        left: 25%;
        padding-right: 0;
        width: fit-content;
    }

    .right__side{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .details{
        position: relative;
        right: 5rem;
        top: 17rem;
        align-items: center;

    }

    .round{
        display: none;
    }
    
    .logo{
        position: relative;
        width: 16rem;
        top: -2rem;
        right: -8rem;
    }

    .contact__section{
        height: 50rem;
    }
}

@media only screen and (max-width: 768px){
    
    .left__side{
        border: none;
        position: relative;
        left: 20%;
    }
    .logo{
        position: relative;
        width: 16rem;
        top: -2rem;
        right: -7rem;
    }

    .details{
        position: relative;
        right: 2rem;
        top: 17rem;
        align-items: center;

    }
}

@media only screen and (max-width: 560px){
    .logo{        
        top: -2rem;
        right: -4rem;
    }

    .left__side{
        left: 18%;
    }

    .details{
        right: 0rem;
        top: 17rem;
    }
}

@media only screen and (max-width: 460px){
    .logo{        
        top: -2rem;
        right: -2rem;
    }

    .left__side{
        left: 7%;
    }

    .details{
        right: 0rem;
        top: 17rem;
    }
}

@media only screen and (max-width: 460px){

    .message__input{
        width: 17rem;
    }
    
    .email{
        width: 12rem;
    }
    
    .action{
        display: flex;
        gap: 1rem;
    }
    
    .button__send{
        color: #000;
        background-color: #fff;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .button__send i{
        font-size: 1.5rem;
        font-weight: bold;
    }
}