.header{
    width: 100%;
    position: fixed; 
    background: url("../../assets/1Artboard/headerbackground.png");
    top: 0;
    /* left: 0; */
    z-index: 100;
    
}

.Menu__btn{
    display: none;
}

.nav{
    height: 2rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem 1rem 1rem 1rem;
    
    
}

.nav__logo img{
    width: 5rem;
   
}

.nav__list{    
    display: flex;
    column-gap: 2rem;
    list-style-type: none;   

}

.nav__menu{
    display: flex;
    flex-direction: row;
    
}

.nav__link{ 
    font-size: var(--normal-font-size);
    color: white;
    font-weight: var(--font-medium);
    transition: .3s;
    text-decoration: none;
}

.nav__link:hover{
    color: #2a94f4;
}

@media only screen and (max-width: 768px){
    .Menu__btn{
        display: block;
    }

    .nav__list{
        display: none;
    }

    .mnubtn{
        color: #fff;
        background: transparent;
        border: none;
        font-size: 2rem !important;
    }

    .sidenav i{
        color: #fff;
        font-size: 2rem;
        
    }
}

