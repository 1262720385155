.why__head{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.TitleImg{
    position: relative;
    top: -16rem;
    height: 50rem;
}

.ManImg{
    height: 30rem;
    transform: scaleX(-1);
    position: relative;
    left: -35rem;
    top: -1.5rem;
}

.choose__cards{
    position: relative;
    top: -20rem;
}

/* Cards */

.card__content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.box__card{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px !important;
    width: 16rem;
    height: 20rem;
    /* border: 1px solid black; */
    border-radius: 25px !important;
    text-align: center;
}

.first__row{
    display: flex;
    justify-content: space-evenly;
    margin-top: 5rem;
}

.card__title{
    position: relative;
    bottom: 1rem;
    color: #0051c4;
}

.boxImg{
    width: 100%;
}

.box__description{
    width: 20rem;
    text-align: justify;
    margin-left: 3rem;
    margin-right: 3rem;
}


/* BREAKPOINTS */

@media only screen and (max-width: 1440px){
    
    .first__row{
        justify-content: space-evenly;
    }

    .box__description{
        width: 15rem;
        /* margin-left: 3rem;
        margin-right: 3rem; */
    }

    
}

@media only screen and (max-width: 1210px){

    .box__description{
        width: 13rem;
        
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .TitleImg{
        left: 6rem;
    }
    
    .ManImg{
        height: 25rem;
        transform: scaleX(-1);
        position: relative;
        left: -28rem;
        top: -1.5rem;
    }
}

@media only screen and (max-width: 1080px){

    .box__description{
        width: 12rem;
        
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media only screen and (max-width: 950px){

    .box__description{
        width: 20rem;
        
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .first__row{
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }

    .card__content{
        position: relative;
        bottom: 1rem;
    }

    .TitleImg{
        left: 6rem;
        top: -12rem;
        height: 40rem;
        width: 100%;
    }
    
    .ManImg{
        height: 20rem;
        transform: scaleX(-1);
        left: -20rem;
    }
}

@media only screen and (max-width: 768px){
    
    .card__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .box__description{
        width: 25rem;
        
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .TitleImg{
        top: -9rem;
        height: 30rem;
    }
    
    .ManImg{
        height: 15rem;
        left: -11rem;
    }

    .choose__cards{
        top: -17rem;
    }
}

@media only screen and (max-width: 480px){
    
    .TitleImg{
        top: -8rem;
        height: 25rem;
        left: 4rem;
    }
    
    .ManImg{
        height: 10rem;
        left: -8rem;
    }

    .choose__cards{
        top: -13rem;
    }

    .box__description{
        width: 20rem;
        
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media only screen and (max-width: 350px){
    .box__description{
        width: 18rem;
        
        margin-left: 3rem;
        margin-right: 3rem;
    }
}